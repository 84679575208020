<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>价值包参数配置</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="goBack">配置策略</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ type }}策略</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row>
          <a-col :span="8" class="isNotList">
            <a-form-item label="策略名称">
              <a-input
                v-decorator="[
                  'strategyName',
                  {
                    rules: [
                      { required: true, message: '请输入策略名称' },
                      { validator: modelNames, message: '不可为空' },
                      { validator: modelNameMax, message: '不可多于50字' }
                    ]
                  }
                ]"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="状态">
              <a-radio-group
                v-decorator="[
                  'status',
                  {
                    rules: [{ required: true, message: '请选择状态' }]
                  }
                ]"
                style="min-width: 192px"
              >
                <a-radio value="NORMAL">启用</a-radio>
                <a-radio value="DISABLE">禁用</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="优先级">
              <a-input-number
                v-decorator="[
                  'priority',
                  {
                    rules: [{ required: true, message: '请输入优先级' }]
                  }
                ]"
                :min="1"
                :max="100"
              ></a-input-number>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" class="isNotList">
            <a-form-item label="生效时间">
              <a-range-picker
                v-decorator="[
                  'time',
                  {
                    rules: [{ required: true, message: '请选择时间' }]
                  }
                ]"
                style="width: 100%"
                allow-clear
                format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change="onChangeTime"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="作用地区">
              <a-tree-select
                v-decorator="[
                  'areaCodes',
                  {
                    rules: [{ required: true, message: '请选择作用地区' }]
                  }
                ]"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="areaData"
                multiple
                :replace-fields="areaTreeFields"
                tree-node-filter-prop="title"
                :allow-clear="true"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
              ></a-tree-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <div class="titleBar">
            <div class="title">
              <span>产品选择</span>
              <a-icon
                type="plus-square"
                theme="twoTone"
                style="font-size: 26px"
                @click="openModal"
              />
            </div>
            <!--            <a-button @click="openPreview">价格预览</a-button>-->
          </div>
        </a-row>
      </a-form>
      <div class="main-content">
        <a-table
          :data-source="chooseProductTableData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          row-key="id"
        >
          <template slot="keyProps" slot-scope="text, record">
            <div v-for="(item, index) in record.keyProps" :key="index">
              {{ item }}
            </div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a-popconfirm
              title="是否确认删除?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onDelete(record.id)"
            >
              <a>删除</a>
            </a-popconfirm>
          </a-space>
        </a-table>
        <div class="footer">
          <a-button type="primary" @click="onSave">提交</a-button>
          <a-button @click="goBack">返回</a-button>
        </div>
      </div>
    </div>
    <a-modal
      title="选择产品"
      :visible="showModify"
      ok-text="确定"
      cancel-text="取消"
      @ok="handleOk"
      @cancel="
        showModify = false;
        modalReportProductCode = JSON.parse(JSON.stringify(reportProductCode));
        modalProductCode = JSON.parse(JSON.stringify(chooseProductCode));
      "
    >
      <a-form :form="createForm" class="advanced-search-form">
        <a-form-item label="产品">
          <div class="tree__select">
            <a-tree-select
              v-model="modalProductCode"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="productList"
              multiple
              :replace-fields="productTreeFields"
              tree-node-filter-prop="title"
              dropdownClassName="tree__select"
              :allow-clear="true"
              tree-checkable
              :show-checked-strategy="SHOW_PARENT"
            ></a-tree-select>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      title="价格预览"
      :visible="showPreview"
      :footer="null"
      width="1200px"
      @cancel="showPreview = false"
    >
      <div class="time">
        作用时间:{{ dtFormat(startTime) }}至{{ dtFormat(endTime) }}
      </div>
      <a-table
        :data-source="previewList"
        :columns="previewColumns"
        :pagination="false"
        :scroll="{ y: 500 }"
      >
        <span slot="platformPrice">
          平台价（元）
          <a-popover placement="top" content="货栈购买价">
            <a-icon type="info-circle" />
          </a-popover>
        </span>
        <span slot="tradePrice">
          进货价（元）
          <a-popover placement="top" content="渠道终端购买价">
            <a-icon type="info-circle" />
          </a-popover>
        </span>
        <span slot="retailPrice">
          零售指导价（元）
          <a-popover placement="top" content="作线下零售参考">
            <a-icon type="info-circle" />
          </a-popover>
        </span>
        <template slot="time" slot-scope="text, record">
          <div class="startTime">
            始：{{
              record.effectuateStartDate
                ? dtFormat(record.effectuateStartDate)
                : ""
            }}
          </div>
          <div class="startTime">
            终：{{
              record.effectuateEndDate
                ? dtFormat(record.effectuateEndDate)
                : "---"
            }}
          </div>
        </template>
      </a-table>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { TreeSelect } from "ant-design-vue";
import { loadArea } from "@/services/DealerService";
import {
  addPolicy,
  fetchPolicyDetail,
  fetchProductsAndSkuTree,
  modifyStrategyOfPolicy,
  strategYpreviewPolicy
} from "@/services/ValueBag";
import moment from "moment";
import { dtFormat, tsFormat } from "@/components/DateUtils";

const areaTreeFields = {
  children: "subAreas",
  title: "areaName",
  key: "areaCode",
  value: "areaCode"
};
const productTreeFields = {
  children: "skuList",
  title: "name",
  key: "id",
  value: "id"
};
const tblColumns = [
  {
    title: "产品名称",
    dataIndex: "productName"
  },
  {
    title: "产品编号",
    dataIndex: "productCode"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode"
  },
  {
    title: "商品规格",
    dataIndex: "keyProps",
    scopedSlots: { customRender: "keyProps" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const previewColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    slots: {
      title: "platformPrice"
    },
    key: "platformPrice",
    dataIndex: "platformPrice"
  },
  {
    slots: {
      title: "tradePrice"
    },
    key: "tradePrice",
    dataIndex: "tradePrice"
  },
  {
    slots: {
      title: "retailPrice"
    },
    key: "retailPrice",
    dataIndex: "retailPrice"
  },
  {
    title: "作用时间",
    key: "time",
    scopedSlots: { customRender: "time" }
  },
  {
    title: "作用区域",
    dataIndex: "areaOfAction"
  },
  {
    title: "生效策略名称",
    dataIndex: "strategyName"
  }
];
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
  data() {
    return {
      moment,
      SHOW_PARENT,
      type: "",
      productTreeFields,
      form: this.$form.createForm(this),
      createForm: this.$form.createForm(this, { name: "createForm" }),
      id: this.$route.params.id,
      tblData: [],
      tblColumns,
      showModify: false,
      chooseProductTableData: [],
      modalProductTableData: [],
      modalProductCode: [],
      reportProductCode: [],
      modalReportProductCode: [],
      areaTreeFields,
      areaData: [
        {
          areaCode: "00",
          areaName: "全国",
          id: "00",
          subAreas: []
        }
      ],
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      endTime: "",
      info: {},
      strategyName: "",
      productList: [],
      getList: [],
      chooseProductDetail: [],
      chooseProductCode: [],
      status: "",
      showPreview: false,
      previewList: [],
      previewColumns
    };
  },
  watch: {
    modalProductCode(newValue) {
      this.fetchChooseProductDetail(newValue);
    }
  },
  mounted() {
    if (this.id) {
      this.type = "修改";
      this.onDetail();
    } else {
      this.type = "新增";
      this.fetchAreaList();
      this.onLoadProductTree();
    }
  },
  methods: {
    tsFormat,
    dtFormat,
    openPreview() {
      let status = this.form.getFieldsValue().status;
      let time = this.form.getFieldsValue().time;
      let priority = this.form.getFieldsValue().priority;
      let areaCodes = this.form.getFieldsValue().areaCodes;
      let chooseProductTableData = this.chooseProductTableData;
      if (
        status &&
        time &&
        time.length > 0 &&
        priority &&
        areaCodes &&
        areaCodes.length > 0 &&
        chooseProductTableData.length > 0
      ) {
        let params = {
          areaCodes: areaCodes.join(","),
          startTime: moment(this.startTime)
            .startOf("day")
            .add("hours", 8),
          endTime: moment(this.endTime)
            .endOf("day")
            .add("hours", 8),
          skuIdList: this.chooseProductCode
        };
        const arr = [...params.skuIdList, ...this.getList];
        let deleteArr = arr.filter(t => {
          return this.getList.includes(t) && !params.skuIdList.includes(t);
        });
        const addArr = arr.filter(t => {
          return params.skuIdList.includes(t) && !this.getList.includes(t);
        });
        params.skuIdList = addArr;
        params.rmSkuIdList = deleteArr;
        if (this.id) {
          params.strategyId = this.id;
        }
        console.log(params);
        strategYpreviewPolicy(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.previewList = resp.data.data;
          }
        });
        this.showPreview = true;
      } else {
        this.$message.info(
          "请先填选策略的 作用商品/开始结束时间/状态、优先级和作用地区！"
        );
      }
    },
    openModal() {
      this.showModify = true;
      this.modalReportProductCode = JSON.parse(
        JSON.stringify(this.reportProductCode)
      );
      this.modalProductCode = JSON.parse(
        JSON.stringify(this.chooseProductCode)
      );
      // this.modalProductCode = this.productList
      //   .filter(
      //     product =>
      //       product.skuList &&
      //       product.skuList.length &&
      //       product.skuList.every(sku =>
      //         this.chooseProductCode.includes(sku.id)
      //       )
      //   )
      //   .map(product => product.productId);
    },
    modelNames(rule, value) {
      if (value.trim()) {
        return true;
      } else {
        return false;
      }
    },
    modelNameMax(rule, value) {
      if (value.length <= 50) {
        return true;
      } else {
        return false;
      }
    },
    // 加载选择的产品内容
    fetchChooseProductDetail(arr) {
      if (arr && arr.length > 0) {
        this.modalProductTableData = [];
        this.modalReportProductCode = [];
        if (this.productList.length > 0) {
          this.productList.forEach(items => {
            arr.forEach(item => {
              const params = {};
              if (item === items.id) {
                if (items.skuList && items.skuList.length > 0) {
                  items.skuList.forEach(itemss => {
                    this.modalReportProductCode.push(itemss.skuId);
                    const keyProps = [];
                    if (itemss.keyPropsMap) {
                      for (const [key, value] of Object.entries(
                        itemss.keyPropsMap
                      )) {
                        if (key) {
                          keyProps.push([key, value].join(":"));
                        }
                      }
                    }
                    params.productCode = items.productCode;
                    params.id = itemss.skuId;
                    params.productName = items.productName;
                    params.skuCode = itemss.skuCode;
                    params.keyProps = keyProps;
                    this.modalProductTableData.push(params);
                  });
                }
              } else {
                if (items.skuList && items.skuList.length > 0) {
                  items.skuList.forEach(itemss => {
                    if (item === itemss.id) {
                      this.modalReportProductCode.push(itemss.skuId);
                      const params = {};
                      if (item === itemss.id) {
                        const keyProps = [];
                        if (itemss.keyPropsMap) {
                          for (const [key, value] of Object.entries(
                            itemss.keyPropsMap
                          )) {
                            if (key) {
                              keyProps.push([key, value].join(":"));
                            }
                          }
                        }
                        params.productCode = items.productCode;
                        params.id = itemss.skuId;
                        params.productName = items.productName;
                        params.skuCode = itemss.skuCode;
                        params.keyProps = keyProps;
                        this.modalProductTableData.push(params);
                      }
                    }
                  });
                }
              }
            });
          });
        }
      } else {
        this.modalProductTableData = [];
        this.modalReportProductCode = [];
      }
      if (!this.showModify) {
        this.chooseProductTableData = JSON.parse(
          JSON.stringify(this.modalProductTableData)
        );
        this.reportProductCode = JSON.parse(
          JSON.stringify(this.modalReportProductCode)
        );
      }
    },
    // 删除产品
    onDelete(id) {
      this.modalProductCode.forEach((item, index) => {
        if (item === id) {
          this.modalProductCode.splice(index, 1);
        }
      });
      this.chooseProductCode = this.modalProductCode;
    },
    // 加载产品树
    onLoadProductTree() {
      fetchProductsAndSkuTree().then(resp => {
        if (resp.data.code === "SUCCESS") {
          const productList = [];
          resp.data.data.forEach(item => {
            if (item.skuList && item.skuList.length > 0) {
              productList.push(item);
            }
          });
          this.productList = productList;
          if (this.productList.length > 0) {
            this.productList.forEach(item => {
              item.id = item.productId;
              item.name = `${item.productName} ${item.productCode}`;
              if (item.skuList && item.skuList.length > 0) {
                item.skuList.forEach(items => {
                  items.id = items.skuId;
                  let skuName = "";
                  if (items.keyPropsMap) {
                    for (const [key, value] of Object.entries(
                      items.keyPropsMap
                    )) {
                      if (key) {
                        skuName += `${value} `;
                      }
                    }
                  }
                  items.name = `${items.skuCode} ${skuName}`;
                });
              }
            });
          }
          if (this.info.skuIdList && this.info.skuIdList.length > 0) {
            this.modalProductCode = this.info.skuIdList;
            this.chooseProductCode = this.info.skuIdList;
          }
        }
      });
    },
    // 获取策略详情信息
    onDetail() {
      fetchPolicyDetail(this.id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          this.form.setFieldsValue({
            strategyName: this.info.strategyName,
            status: this.info.status,
            priority: this.info.priority,
            time: [moment(this.info.startTime), moment(this.info.endTime)]
          });
          this.startTime = moment(this.info.startTime);
          this.endTime = moment(this.info.endTime);
          this.getList =
            this.info.skuIdList && this.info.skuIdList.length > 0
              ? JSON.parse(JSON.stringify(this.info.skuIdList))
              : [];
          this.fetchAreaList();
          this.onLoadProductTree();
        }
      });
    },
    // 获取地理信息
    fetchAreaList() {
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaData[0].subAreas = resp.data.data;
          if (this.type === "修改") {
            this.form.setFieldsValue({
              areaCodes: this.info.areaCodes
                ? this.info.areaCodes.split(",")
                : null
            });
          }
        }
      });
    },
    // range结构改造
    range(start, end) {
      const result = [];
      // eslint-disable-next-line no-plusplus
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    // 不可选日期
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().startOf("day");
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (date && date.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    handleOk() {
      this.modalProductCode = JSON.parse(
        JSON.stringify(this.modalReportProductCode)
      );
      this.chooseProductTableData = JSON.parse(
        JSON.stringify(this.modalProductCode)
      );
      this.chooseProductCode = JSON.parse(
        JSON.stringify(this.modalReportProductCode)
      );
      this.reportProductCode = JSON.parse(
        JSON.stringify(this.modalReportProductCode)
      );
      this.showModify = false;
    },
    // 提交
    onSave() {
      console.log(this.startTime);
      console.log(this.endTime);
      console.log(
        moment(this.startTime)
          .add("hours", 8)
          .startOf("day")
      );
      console.log(
        moment(this.endTime)
          .add("hours", 8)
          .endOf("day")
      );
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.areaCodes && values.areaCodes.length > 0) {
            values.areaCodes = values.areaCodes.join(",");
          }
          values.startTime = moment(this.startTime)
            .startOf("day")
            .add("hours", 8);
          values.endTime = moment(this.endTime)
            .endOf("day")
            .add("hours", 8);
          values.skuIdList = this.chooseProductCode;
          const arr = [...values.skuIdList, ...this.getList];
          let deleteArr = arr.filter(t => {
            return this.getList.includes(t) && !values.skuIdList.includes(t);
          });
          const addArr = arr.filter(t => {
            return values.skuIdList.includes(t) && !this.getList.includes(t);
          });
          values.skuIdList = addArr;
          values.rmSkuIdList = deleteArr;
          if (this.id) {
            values.strategyId = this.id;
            modifyStrategyOfPolicy(values).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("修改成功");
                this.$store.state.hasChange = "1";
                this.$router.go(-1);
              }
            });
          } else {
            addPolicy(values).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("新增成功");
                this.$store.state.hasChange = "1";
                this.$router.go(-1);
              }
            });
          }
        }
      });
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
  margin-right: 40px;
}
.isNotList /deep/ .ant-form-item .ant-form-item-label {
  min-width: 0;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.btn-reset {
  margin-bottom: 8px;
}

.search__btn {
  margin-right: 8px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 0;
  color: #333333;
}
.title span {
  margin-right: 12px;
}
.footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.footer button {
  margin-right: 8px;
}
/deep/.ant-modal {
  min-width: 724px !important;
}
/*/deep/.tree__select .ant-select-tree li ul .ant-select-tree-checkbox-inner {*/
/*  visibility: hidden !important;*/
/*}*/
.titleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleBar .btn {
}
</style>
