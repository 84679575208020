<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>价值包参数配置</a-breadcrumb-item>
        <a-breadcrumb-item>配置策略</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <a-button type="primary" style="margin-bottom: 12px" @click="onAdd">
        新增
      </a-button>
      <a-popconfirm
        title="是否确认生成实时价格?"
        ok-text="确认"
        cancel-text="取消"
        @confirm="initPolicy"
      >
        <a-button :loading="initPolicyLoading" style="margin-left: 12px;">
          生成实时价格
        </a-button>
      </a-popconfirm>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="action" slot-scope="text, record">
            <a-space>
              <a @click="onModify(record.strategyId)">修改</a>
              <a @click="onAddRule(record.strategyId)">配置规则</a>
              <a @click="onCopy(record.strategyId)">复制策略</a>
              <a-popconfirm
                title="是否确认删除?"
                ok-text="确认"
                cancel-text="取消"
                @confirm="onDelete(record.strategyId)"
              >
                <a>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { dtFormat } from "@/components/DateUtils";
import {
  copyStrategy,
  deleteStrategy,
  fetchStrategyList,
  strategyInitPolicy
} from "@/services/ValueBag";
import { removeKeepAliveCache } from "@/components/keepAlive";

const tblColumns = [
  {
    title: "策略名称",
    dataIndex: "strategyName"
  },
  {
    title: "状态",
    dataIndex: "showStatus"
  },
  {
    title: "开始时间",
    dataIndex: "startTime"
  },
  {
    title: "结束时间",
    dataIndex: "endTime"
  },
  {
    title: "优先级",
    dataIndex: "priority"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  data() {
    return {
      initPolicyLoading: false,
      fields: [
        {
          label: "策略名称",
          key: "strategyName",
          component: "j-input"
        },
        {
          label: "状态",
          key: "status",
          component: "j-radio-group",
          options: [
            {
              label: "启用",
              value: "NORMAL"
            },
            {
              label: "禁用",
              value: "DISABLE"
            }
          ]
        },
        {
          label: "使用时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {},
      tblData: [],
      tblColumns,
      hasChange: false,
      showModify: true,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false
    };
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.name === "AddStrategy" ||
      from.name === "ModifyStrategy" ||
      from.name === "StrategyRule" ||
      from.name === "AddStrategyRule" ||
      from.name === "ModifyStrategyRule"
    ) {
      next(vm => {
        vm.hasChange = vm.$store.state.hasChange;
      });
    } else {
      next(vm => {
        vm.hasChange = false;
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === "AddStrategy" ||
      to.name === "ModifyStrategy" ||
      to.name === "StrategyRule" ||
      to.name === "AddStrategyRule" ||
      to.name === "ModifyStrategyRule"
    ) {
      this.$route.meta.keepAlive = true;
    } else {
      removeKeepAliveCache.call(this);
      this.$route.meta.keepAlive = true;
    }
    next();
  },
  watch: {
    hasChange(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.fetchList();
        this.hasChange = false;
        this.$store.state.hasChange = false;
      }
    }
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    //更新价格
    initPolicy() {
      this.initPolicyLoading = true;
      strategyInitPolicy().then(resp => {
        this.initPolicyLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("操作成功");
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 修改策略
    onModify(id) {
      this.$router.push({
        name: "ModifyStrategy",
        params: {
          id: id
        }
      });
    },
    // 前往配置规则页面
    onAddRule(id) {
      this.$router.push({
        name: "StrategyRule",
        params: {
          id: id
        }
      });
    },
    //复制策略
    onCopy(id) {
      copyStrategy(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("复制成功");
          this.fetchList();
        }
      });
    },
    // 删除策略
    onDelete(id) {
      deleteStrategy(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.fetchList();
        }
      });
    },
    // 获取列表信息
    fetchList() {
      const params = {
        strategyName: this.form.strategyName || "",
        status: this.form.status || "",
        startTime: this.form.startTime ? this.form.startTime + " 00:00:00" : "",
        endTime: this.form.endTime ? this.form.endTime + " 23:59:59" : "",
        corporationId: "",
        areaCode: "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchStrategyList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          records.forEach(item => {
            if (item.startTime) {
              item.startTime = dtFormat(item.startTime);
            } else {
              item.startTime = "暂无数据";
            }
            if (item.endTime) {
              item.endTime = dtFormat(item.endTime);
            } else {
              item.endTime = "暂无数据";
            }
            if (item.status === "NORMAL") {
              item.showStatus = "启用";
            }
            if (item.status === "DISABLE") {
              item.showStatus = "禁用";
            }
          });
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(item => {
              item.createTime = dtFormat(item.createTime);
            });
          }
          this.tblData = records;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.fetchList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    //前往新增页
    onAdd() {
      this.$router.push({
        name: "AddStrategy"
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.btn-reset {
  margin-bottom: 8px;
}

.search__btn {
  margin-right: 8px;
}
</style>
